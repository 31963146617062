.rbq_question_overlap_text {
  font-size: 42px !important;
}

.rbq_answer_text {
  font-size: 24px !important;
}

.multipleChoice__optionKeyTip,
.multipleChoice__optionKey {
  display: none !important;
  visibility: hidden !important;
}

.gpt-result {
  white-space: pre-wrap;
  min-height: 100%;
  height: 100%;
}

.multipleChoice__optionWrapper .multipleChoice__optionLabel {
  max-width: 100% !important;
}

.renderer-components-form-footer {
  top: 0;
  bottom: unset !important;
}

.renderer-core-correct-incorrect-result {
  display: none !important;
}

.qf-welcome-screen-block__action-wrapper .renderer-core-html-parser,
.renderer-core-field-action__helper-text .renderer-core-html-parser,
.renderer-core-field-action .renderer-core-button {
  display: none;
}

.renderer-core-submit-btn {
  display: inline-flex !important;
}

.selected {
  color: white !important;
  font-weight: 700 !important;
}

.gpt-result-grid {
  justify-content: center;
  align-items: center;
  /* background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/coc-background.jpg);
    background-repeat: no-repeat;
    background-size: cover; */
  height: 100%;
  /* padding: 50px 0px; */
}

.see-more {
  color: #f9584c;
  text-decoration: underline;
  background-color: none;
  background: none;
  border: none;
  cursor: pointer;
}

.multipleChoice__optionWrapper {
  min-width: 40% !important;
}

.MuiSelect-select {
  padding: 10px 32px 10px 10px !important;
}

.renderer-components-field-content {
  min-width: 1100px !important;
}

.form {
  max-width: 60%;
  margin: auto;
}

form {
  width: 100%;
}

.MuiList-root {
  max-width: 80% !important;
  margin: auto !important;
}

.MuiListItem-root {
  justify-content: center !important;
}

.skip {
  background-color: white !important;
  color: #000 !important;
  box-shadow: none !important;
  width: 224px;
  padding: 10px 50px !important;
  border: 1px solid #000 !important;
}

.continue {
  box-shadow: none !important;
  width: 210px;
  border-radius: 4px !important;
  padding: 10px 80px;
  border: 1px solid #2c291e !important;
  background-color: #2c291e !important;
  margin-left: 25px !important;
}

.feedback-page-btn {
  text-align: right;
}

.feedback-btn {
  box-shadow: none !important;
  width: auto;
  border-radius: 4px !important;
  padding: 10px 60px !important;
  border: 1px solid #e44142 !important;
  background-color: #e44142 !important;
}

/* .borderBottomCreate {
  border-bottom: 1px solid #e44142;
} */

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #e44142;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e44142;
}

.feedback-form {
  margin-top: 30px;
}

.renderer-components-form-footer__inner {
  justify-content: flex-start !important;
}

.renderer-components-form-footer__inner,
.renderer-components-form-footer {
  width: 50%;
}

@media (max-width: 991px) {
  .renderer-components-field-content {
    min-width: 0 !important;
  }

  .renderer-core-block-scroller {
    display: block !important;
  }

  .renderer-components-block-description .renderer-core-html-parser p {
    line-height: 18px !important;
  }
}

@media only screen and (max-width: 767px) {
  .mob-card {
    margin-bottom: 100px;
    margin-top: 20px !important;
  }

  .gpt-result {
    white-space: pre-wrap;
    height: 100%;
    overflow-x: hidden;
  }

  .renderer-components-field-content {
    min-width: 96% !important;
  }

  .form {
    max-width: 96%;
  }

  .feedback-page-btn {
    text-align: center;
  }

  .MuiList-root {
    max-width: 100% !important;
  }

  /* .renderer-components-form-footer__inner,
  .renderer-components-form-footer {
    justify-content: start !important;
    width: 50%;
   
  } */

  .renderer-components-form-footer__inner,
  .renderer-components-form-footer {
    display: none !important;
  }
}

.choose-priority {
  height: 76vh !important;
  overflow-y: scroll !important;
}