/* Header.css */
.footer {
  bottom: 0;
  background-color: #e44142;
  color: #fff;
  z-index: 999999;
  padding: 60px 0px 20px 0px;
}

.footer_page {
  padding: 4px 40px;
  font-size: 20px;
  cursor: pointer;
}

.social_media ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.social_media ul li {
  margin-right: 10px;
}

.social_media ul li:last-child {
  margin: 0px;
}

.social_media ul li a img {
  width: 20px;
}

.footer-title h5 {
  font-size: 30px;
  margin: 0 0 15px;
}

.footer_links a.footer-page {
  margin-right: 10px;
}

.footer_links a.footer-page:last-child {
  margin-right: 0px;
}

.social_media {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  footer {
    display: flex;
    flex-direction: column;
  }

  .footer_page {
    font-size: 14px;
    padding: 2px 10px;
    cursor: pointer;
    margin: 10px;
  }

  .footer_links {
    flex-direction: column;
    display: flex;
    text-align: end;
    margin-top: 10px;
  }

  .footer_links a {
    margin: 0px !important;
  }

  .social_media {
    flex-direction: column;
    align-items: unset;
  }
}

.social_media a {
  cursor: pointer;
}

@media only screen and (max-width: 282px) {
  .footer-inner {
    margin-top: 30px;
  }
}