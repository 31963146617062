.homepage {
  display: flex;
  // height: calc(100vh - 96px);
  height: calc(100vh - 113px);
  align-items: center;
  justify-content: center;

  .homepage-inner {
    width: 100%;
    height: 100%;

    h1 {
      text-align: center;
    }

    .row {
      display: flex;
      width: 100%;
    }

    .box {
      flex: 1;
      margin: 5px;
      max-height: 100%;

      .box-image {
        position: absolute;
        width: 600px;
        top: 100px;
        right: 0;

        img {
          mix-blend-mode: multiply;
          width: 100%;
        }
      }

      .btn {
        border-radius: 8px;
        background: #e44142;
        flex-shrink: 0;
        color: #fff;
        font-weight: 500;
        border: none;
        font-size: 20px;
        cursor: pointer;
        padding: 12px 60px;
        text-decoration: none;
        width: 500px;
        margin-top: 50px;
      }

      p {
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        width: 450px;
        text-align: left;
        height: 240px;
        color: #2c291e;
        margin-bottom: 40px;
      }
    }

    .home-text {
      padding-top: 30px;
    }

    .dog-box {
      position: relative;
    }
  }
}

@media (max-width: 991px) {
  .box-image {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
  }

  .box-1 {
    order: 2;
  }

  .box-2 {
    order: 1;
  }
}

@media (max-width: 767px) {
  .box-image {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
  }

  .box-1 {
    order: 2;
  }

  .box-2 {
    order: 1;
  }

  p {
    width: auto !important;
  }

  .homepage .homepage-inner .home-text {
    padding: 20px;
    padding-top: 0px;
    text-align: center;

    p {
      text-align: center;
      font-size: 20px !important;
      height: 130px;
      margin-bottom: 20px;
    }

    a {
      width: 60% !important;
      display: block;
    }
  }

  .homepage .homepage-inner .box p {
    // margin-bottom: 40px;
    margin-top: 0px;
    font-size: 30px;
  }

  .home-inner {
    padding-right: 0 !important;
  }
}