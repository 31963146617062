.privacy-page {
  padding: 20px;
}

ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

table {
  border: solid 1px #eeeeee;
}

table tbody td {
  border-bottom: 1px solid #eee;
}

.cookie-table {
  width: 100%;
  border-collapse: collapse;
}

.cookie-table th,
.cookie-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.cookie-table th {
  background-color: #f7f7f7;
  font-weight: bold;
}
