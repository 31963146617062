/* Header.css */
.header {
  background-color: transparent;
  color: #000;
  padding: 20px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .header {
    height: 50px;
  }
}

.logo {
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  background: none;
  border: none;
  color: #2C291E;
}

.nav {
  list-style: none;
  display: flex;
  color: #fff;
}

.nav-item {
  margin-right: 20px;
}

/* Style the anchor links in the navigation */
.nav-item a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  transition: color 0.2s;

  &:hover {
    color: #007bff;
  }

}

nav.langeswitch .MuiInputBase-root {
  color: #000 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #000 !important;
}

.css-bpeome-MuiSvgIcon-root-MuiSelect-icon,
.MuiSelect-iconOutlined {
  color: #000 !important;
}