.results-card {
  //background: white !important;
  width: 350px !important;
  display: inline-block !important;
  position: relative !important;
  margin: auto !important;
  border-radius: 8px !important;
  text-align: left !important;
  z-index: 9999 !important;
  overflow: visible !important;
  // transition: transform 0.3s ease;
  transition: all 0.9s ease-in-out !important;

  .MuiCardContent-root {
    padding: 0px !important;
  }

  .dogContent {
    margin-top: -8px;
  }

  .dogInfo {
    padding: 15px;
    background-color: #f2f2f2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;

    .dogName {
      font-size: 16px;
      text-align: left;
      display: block;
    }

    .dogMatch {
      font-size: 16px;
      background-color: #000;
      color: #fff;
      border-radius: 22px;
      padding: 5px 10px;
      border: 0;
    }
  }
}

// .results-card:hover {
//     transform: rotateY(45deg);
//   }

.result-card-bottom {
  background-color: #e44142;
  text-align: center;
  color: #fff;
  padding: 5px;
  word-wrap: break-word;
  height: 120px;

  h3,
  p {
    margin: 0px;
    padding: 5px 0px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
  }
}

.right-side {
  border-radius: 0 0 0 7px;
}

.results-div {
  // background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/barbarian-bg.jpg');
  position: relative;
  height: 230px;
  // margin-bottom: 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}

/* FlippingCard.css */
.flipping-card {
  // perspective: 1000px;
  width: 200px;
  height: 400px;
  position: relative;
  //transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform-origin: center right;
  //transition: transform 0.7s all ease-in-out !important;
}

.flipping-card.flipped {
  transform: translateX(-100%) rotateY(-180deg);
}

.card-face {
  position: absolute;
  width: 350px;
  backface-visibility: hidden;
  cursor: pointer;
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
}

.card-face.front.clicked {
  border: 2px solid #e44142;
}

.back {
  transform: rotateY(180deg);
  background-color: #e44142;
  color: #fff;
  width: 350px;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
}

.dogInfo-back {
  padding: 20px;
  color: #fff;
  height: 400px;
  overflow: auto;
}

.MuiLinearProgress-root {
  background-color: #b71f20 !important;
  border-radius: 5px;
}

.MuiLinearProgress-bar {
  background-color: #fff !important;
  border-radius: 5px;
}

.highlight-text {
  font-size: 12px;
  font-weight: 600;
  margin-right: 5px;
}

.progressBar-main {
  // display: flex;
  align-items: center;
}

span.progressBar {
  width: 100%;
}

span.progressBar-name {
  width: 100%;
}

.mobile-text {
  display: none;
}

.info_percent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .more_info {
    color: #b71f20;
    font-size: 16px;
    line-height: normal;
    border-bottom: 1px solid #b71f20;
  }
}

.error_msg {
  text-align: center;
  height: calc(100vh - 83px);
  justify-content: center;
  align-items: center;
  display: flex;
}

.more_result {
  font-size: 16px;
  cursor: pointer;
  background-color: #e44142;
  color: #fff;
  border-radius: 22px;
  padding: 5px 10px;
  width: 40%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
  border: 0;
}

.more_result_div {
  text-align: center;
  padding: 0 20px;
  margin-top: 30px;
}

.card_like {
  position: absolute;
  bottom: -100px;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card_like img {
  width: 20px;
  cursor: pointer;
}

.combineName p {
  width: 100% !important;
}

@media (max-width: 990px) {
  .card_like {
    display: none;
  }
}

@media (max-width: 767px) {
  .slick-dots {
    bottom: 0 !important;
  }

  .results-card {
    width: 300px !important;
  }

  .card-face {
    width: 300px !important;
  }

  .back {
    width: 300px !important;
  }

  .mobile-text {
    display: flex;
    padding: 0 20px;
  }

  span.count {
    width: calc(100% - 140px);
  }

  span.count-text {
    text-align: center;
    width: calc(100% - 20px);
    color: #b71f20;
  }

  .more_result_div {
    margin-top: 20px;
  }

  .more_result {
    width: 90%;
    display: block;
  }
}