.renderer-core-progress-bar {
  background-color: transparent !important;
}

.renderer-core-progress-bar .renderer-core-progress-bar__track {
  position: fixed;
  top: 0;
  margin-top: 0;
  right: 0;
  left: 0;
}

.renderer-components-block-description p {
  font-size: 15px;
}

.renderer-core-progress-bar .renderer-core-progress-bar__track {
  background: none !important;
}

.css-9o65i7 {
  background: none !important;
  border-color: #2c291e !important;
  color: #2c291e !important;
}

.css-9o65i7.selected {
  background: #e44142 !important;
  border-color: #e44142 !important;
  color: #fff !important;
  font-weight: 500 !important;
}

// .renderer-core-form-flow__wrapper
//   .renderer-core-form-flow
//   .renderer-components-form-footer {
//   z-index: 0 !important;
// }

.continue {
  background-color: #000 !important;
}

.back-btn-div {
  position: absolute;
  left: 30px;
  top: 30px;
}

.btn-back,
.btn-back:hover {
  color: #eb7071 !important;
  margin: 10px !important;
  background: none !important;
  border-bottom: 2px solid #e44142 !important;
  box-shadow: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
  min-width: auto !important;
  cursor: pointer;
}

.Mui-checked,
.MuiCheckbox-indeterminate {
  color: #e44142 !important;
}

img.dog-leg {
  position: absolute;
  right: 120px;
  bottom: 0;
  z-index: 9;
  width: 15%;
}

.priority-ans {
  padding-left: 15px;
  color: #e44142;
}

.multipleChoice__optionWrapper {
  max-width: 40% !important;
}

.pri-btn {
  padding: 10px 80px !important;
}

span.multipleChoice__optionLabel {
  padding: 5px;
}

.multipleChoice__optionWrapper {
  margin: 0 36px 42px 0 !important;
  padding: 10px 36px !important;
}

.back-btn-div1 {
  position: absolute;
  left: 30px;
  top: 30px;
}

.btn-back1,
.btn-back1:hover {
  color: #eb7071 !important;
  margin: 30px !important;
  background: none !important;
  border-bottom: 2px solid #e44142 !important;
  box-shadow: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
  min-width: auto !important;
  cursor: pointer;
}

// ul.MuiList-root.MuiList-padding {
//     padding-bottom: 60px !important;
// }

.swipe-text {
  display: none;
}

@media (max-width: 991px) {
  img.dog-leg {
    position: absolute;
    right: 10px;
    bottom: 0;
    z-index: 9;
    width: 40%;
  }

  .multipleChoice__optionWrapper {
    max-width: 42% !important;
  }

  .renderer-components-field-content {
    min-width: 0 !important;
    width: 100% !important;
  }

  .renderer-core-progress-bar {
    padding: 0;
    width: 120px;
  }

  .btn-back,
  .btn-back:hover {
    top: -78px !important;
  }
}

@media (max-width: 767px) {
  img.dog-leg {
    width: 40%;
    right: 20px;
  }

  .renderer-components-block-description p {
    font-size: 13px;
  }

  .back-btn-div {
    position: absolute;
    left: 30px;
    top: 35px;
  }

  .back-btn-div1 {
    position: absolute;
    left: 30px;
    top: 35px;
  }

  .continue {
    padding: 10px 70px !important;
  }

  .skip {
    padding: 10px 20px !important;
  }

  .choose-priority {
    padding-top: 50px !important;
    height: 76vh !important;
    overflow-y: scroll;
  }

  .renderer-core-progress-bar {
    padding: 0;
    width: 100%;
  }

  .renderer-core-progress-bar .renderer-core-progress-bar__label {
    display: block !important;
    font-size: 20px;
    float: right;
  }

  .renderer-core-progress-bar__label.renderer-core-html-parser {
    float: right;
  }

  .renderer-components-field-wrapper .renderer-components-field-wrapper__content-wrapper {
    flex-wrap: nowrap !important;
    //    align-items: unset !important;
  }

  .priority-btn {
    right: 5% !important;
    width: 90% !important;
    margin: 5px;
    bottom: 0;
    flex-direction: column;
  }

  .priority-btn .btn {
    width: 80%;
    margin-bottom: 15px;
  }

  .priority-btn .btn:last-child {
    margin: 0 !important;
  }

  // .renderer-components-field-wrapper .renderer-components-field-wrapper__content-wrapper .renderer-core-block-scroller
  // {
  //     align-items: unset !important;
  //     padding-top: 50px !important
  // }

  .multipleChoice__optionWrapper {
    max-width: 90% !important;
    margin: 0px 20px 20px 0px !important;
    padding: 7px 16px !important;
  }

  .renderer-components-field-wrapper .renderer-components-field-wrapper__content-wrapper .renderer-core-block-scroller {
    padding: 40px 14px;
  }

  .btn-back,
  .btn-back:hover {
    top: 0 !important;
    margin: 0 !important;
    left: -11px !important;
  }

  .swipe-text {
    display: block;
    padding: 0 34px;
    color: #e44142;
    margin-top: 10px;
    text-align: center;
  }
}

.powered {
  color: darkgrey;
  font-weight: 500;
}